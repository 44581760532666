import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { API_BASE_URL, API_END_POINTS } from 'src/app/services/constants';
import { map } from 'rxjs/operators';

// If you are adding any new key, remember to clear it out on logout
const ACESS_TOKEN_KEY = 'access_token';
const ROLE_KEY = 'role';
const PHONE_KEY = 'phone_number';
const EMAIL_KEY = 'email';
const PROFILE_IMAGE_KEY = 'profile_img_key';
const NAME_KEY = 'name_key';
@Injectable()
export class LoggedInUserService {
  public usersList: any = [];
  private apiBaseUrl = API_BASE_URL;

  constructor(private localStorage: LocalStorageService, public _http: HttpClient) {
    // this.getUsers();
  }
  public set accessToken(v: string) {
    this.localStorage.setItem(ACESS_TOKEN_KEY, v);
  }
  public set name(v: string) {
    this.localStorage.setItem(NAME_KEY, v);
  }

  public set phone(v: string) {
    this.localStorage.setItem(PHONE_KEY, v);
  }

  public set email(v: string) {
    this.localStorage.setItem(EMAIL_KEY, v);
  }

  public set role(v: string) {
    this.localStorage.setItem(ROLE_KEY, v);
  }
  public set profileImage(v: string) {
    this.localStorage.setItem(PROFILE_IMAGE_KEY, v);
  }

  public get accessToken(): string {
    return this.localStorage.getItem(ACESS_TOKEN_KEY);
  }

  public get name(): string {
    return this.localStorage.getItem(NAME_KEY);
  }

  public get phone(): string {
    return this.localStorage.getItem(PHONE_KEY);
  }

  public get email(): string {
    return this.localStorage.getItem(EMAIL_KEY);
  }

  public get role(): string {
    return this.localStorage.getItem(ROLE_KEY);
  }

  public get profileImage(): string {
    return this.localStorage.getItem(PROFILE_IMAGE_KEY);
  }


  logout = () => {
    this.localStorage.clearItem(ACESS_TOKEN_KEY);
    this.localStorage.clearItem(ROLE_KEY);
    this.localStorage.clearItem(NAME_KEY);
    this.localStorage.clearItem(PHONE_KEY);
    this.localStorage.clearItem(EMAIL_KEY);
    this.localStorage.clearItem(PROFILE_IMAGE_KEY);
    this.localStorage.clearAll();
  }
  public get isEmployee(): boolean {
    if (this.accessToken && this.accessToken.length > 0) {
      return true;
    }
    return false;
  }
  public getUsers() {
    return this._http.get(`${this.apiBaseUrl}/admin/users/`).pipe(map ( (response: any) => {
      this.usersList = response['docs'];
      return this.usersList;
    }));
  }
}
