import { InstructionsComponent } from './instructions/instructions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { DashboardComponent} from './dashboard/dashboard.component'
import { BrowserSupportGuard } from './browser-support.guard';
import { HomeAuthGuard } from './home-auth-gaurd';
import { LoginComponent } from './login/login.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [BrowserSupportGuard, HomeAuthGuard]
  },
  {
    path: 'instructions',
    component: InstructionsComponent,
    pathMatch: 'full',
    canActivate: [BrowserSupportGuard, HomeAuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'unsupportedBrowser',
    component: BrowserNotSupportedComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
