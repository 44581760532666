import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoggedInUserService } from './loggedin-user.service';
// import { emitter } from '../../features/includes/busy/busy.component';
import { API_BASE_URL } from './constants';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ServerURLInterceptor implements HttpInterceptor {
    constructor( 
        private loggedInUser: LoggedInUserService, 
        private router: Router, 
        private deviceDetector: DeviceDetectorService,
        private notification: ToastrService) {
      }
      intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        let authReq;
        // if (this.loggedInUser.accessToken && req.url.indexOf(API_BASE_URL) !== -1 ) {
        //   let browser: string = this.deviceDetector.getDeviceInfo().browser;
        //   if (!browser) {
        //     browser = '';
        //   }
        //   if (req.method === 'GET' && browser.toLowerCase().indexOf('ie') !== -1) {
        //     authReq = req.clone({
        //       params: req.params.set('iebust', '' + Date.now()),
        //       headers: req.headers.set('Authorization', `Bearer ${this.loggedInUser.accessToken}`)
        //     });
        //   } else {
        //     authReq = req.clone({
        //       headers: req.headers.set('Authorization', `Bearer ${this.loggedInUser.accessToken}`)
        //     });
        //   }
        // } else {
          authReq = req;
        // }
        // var msg = '';
        // if (authReq.url.indexOf('save') !== -1) {
        //   msg = 'Saving...';
        // } else {
        //   msg = '';
        // }
        // if (authReq.method === 'POST' || authReq.method === 'PUT') {
        //   emitter.next({ "val": true, "msg": msg });
        // }
    
        return next.handle(authReq).pipe(catchError((res) => {
    
    
          if (authReq.method === 'POST' || authReq.method === 'PUT') {
            // emitter.next({ "val": false, "msg": msg });
          }
          if (typeof res.error === 'string') {
            res.error = JSON.parse(res.error);
          }
          if (res.status === 0 || (res.status < 599 && res.status > 399 && res.status !== 401
            && res.status !== 406 && res.status !== 410 && res.status !== 423)) {
            this.showErrorFromResponse(res);
          } else if (res.status === 401 && this.loggedInUser.accessToken) {
            this.router.navigate(['/login']);
          } else if (res.status === 406) {
              // answer mandatory error
                  this.showErrorFromResponse(res);
          } else if (res.status === 423) {
            // already responded show thank you message
            this.router.navigate(['/preview/completedSurvey']);
          } else if (res.status === 410) {
            // survey is closed show Thank you for showing interest but the survey is already closed
            this.router.navigate(['/preview/closed-survey']);
          } else if (res.error && res.error.__globals) {
            res.error.__globals.forEach(error => {
              // this.e.set(error, 'error', res.url);
              this.notification.error(error);
              return;
            });
          }
    
          return throwError(res);
        }) );
      }
      showErrorFromResponse(res) {
        const erroBody = res.error;
        delete erroBody.code;
        let foundError = false;
        Object.keys(erroBody).forEach((type) => {
          if (typeof type === 'string' && typeof erroBody[type] === 'string') {
            // this.e.set(erroBody[type], type, res.url);
            this.notification.error(erroBody[type]);
            foundError = true;
          } 
        });
        if (!foundError && res.message && typeof res.message === 'string' ) {
          let genericErrorMsg = 'Please check your network and try again' ;
          this.notification.error(genericErrorMsg);
        }
      }
}
