
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { RequestBase, LoggedInUserService } from 'src/app/services';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit, HostListener } from '@angular/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public surveyList = [];
  totalSurveys = 0;
  totalSharedSurveys = 0;
  managerName = '';
  displayedColumns = [
    'name',
    'empId',
    // 'link',
    'status',
    'percent'
  ];
  dataSource = new MatTableDataSource<any>(this.surveyList);
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    location.reload();
    console.log('reloading the tab')
  }
  @HostListener("document:visibilitychange", ["$event"])
handleVisibilityChange(event: any): void {

    // Route to home page to run the activate Guard
    if (!document.hidden) {          
      location.reload();
      console.log('reloading the tab')
    }
}
  constructor( private router: Router,
    private requestBase: RequestBase,
    private loggedInUser: LoggedInUserService) { 
      this.managerName = this.loggedInUser.name;
    }



    ngOnInit() {
      this.setupSort();
    }
    linkClickedForEmployee(emp) {
      const path = emp.link;
      window.open(path);

    }
    setupSort() {
      this.dataSource.sort = this.sort;
      this.dataSource.sortData = (data, sort: MatSort) => {
        data = data.filter(survey => !survey['is_subsurvey']);
        data = this.sortData(data, sort);
        return data;
      };
    }
    ngAfterViewInit() {
      // this.dataSource.data = this.surveyList;
      this.dataSource.paginator = this.paginator;
      this.paginator.pageIndex = 0;
      this.paginator.page.subscribe( (pageEvent) => {
        // this.collapseExpandedCells();
        this.paginator.pageIndex = pageEvent.pageIndex;
        // console.log('page event triggered for paginator:', pageEvent);
        // if (Math.min(((this.paginator.pageIndex + 1) * this.paginator.pageSize), this.paginator.length) > this.surveyList.length) {
          this.getSurveys();
        // }
      });
      this.getSurveys();
    }
    getSurveys() {
        this.fetchSurveys();
    }
    fetchSurveys() {
      const params = {email:this.loggedInUser.email, password: this.loggedInUser.accessToken};
        this.requestBase.login(params).subscribe(response => {
            this.surveyList = response['employeeData'];
            this.totalSurveys = this.surveyList.length;
          this.dataSource = new MatTableDataSource(this.surveyList);
          this.setupSort();
        });
    }
    sortData(data: any[], sort): any[] {
      if (!sort.active || sort.direction === '') { return data; }
      return data.sort((a, b) => {
        let propertyA: number|string|Date = a[sort.active];
        let propertyB: number|string|Date = b[sort.active];
        switch (sort.active) {
          case 'Owner': [propertyA, propertyB] = [a.owner.displayName, b.owner.displayName]; break;
        }
        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
        return (valueA < valueB ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
    logoutUser(){
      localStorage.clear();
      this.router.navigate(["login"]);
    }
    isCompleted(index) {
      let element = this.dataSource.data[index];
      if (element.percent === '100%') {
        return true;
      }
      return false;
    }
    statusForSurvey(element) {
      if (element && element.status) {
        const status = element.status.split('_').join(' ');
        return status;
      }
      return '';
    }
}
