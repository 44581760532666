<div class="main-container-wrapper">
<app-header [showUser]=true></app-header>
<div class="middle-content">
<p class="header">
  Welcome to Century Group : Behavioural Assessment and Development Initiative.
</p>
<p class="head-text">We appreciate your participation. Each survey is expected to take 10-15 minutes. </p>
<p  class="header">Instructions:</p>
<ul>
    <li>To proceed click on the next arrow below.</li>
    <li>Click on any name to open the survey for assessing the individual employee.</li>
    <li>You will receive survey navigation instructions once you start a survey.</li>
    <li>Even if a survey is closed in between, all progress will be saved and upon logging again, you may start where you left.</li>
    <li>Please assess all your employees.</li>
  </ul>
</div>
<div class="footer">
  <!-- <button (click)="logoutUser()" class="btn-custom right-action">Logout</button> -->
  <button class="btn-custom right-action" (click)="nextBtnClicked()">Next</button>
</div>
