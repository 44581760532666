import { Component, OnInit, Input } from '@angular/core';
import { LoggedInUserService } from 'src/app/services/loggedin-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userName: String;
  @Input() showUser = false;
  @Input() showUserName = false;
  @Input() logoutCallBack;
  public managerName = ''

  constructor(private loggedInUser: LoggedInUserService,
    private route: ActivatedRoute,
    private router: Router,
  private notif: ToastrService) {
    this.managerName = this.loggedInUser.name;
  }

  ngOnInit() {
  }
  public get username(): String {
    return this.loggedInUser.name;
  }
  logoutUser(){
    localStorage.clear();
    this.router.navigate(["login"]);
  }
}
