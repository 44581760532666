import { HeaderComponent } from './header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from "@angular/common/http";
import { MatCheckboxModule, MatInputModule,
  MatTableModule, MatPaginatorModule, MatSortModule,
   MatTooltipModule } from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { APP_PROVIDERS } from './app.providers';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { InstructionsComponent } from './instructions/instructions.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    BrowserNotSupportedComponent,
    HeaderComponent,
    InstructionsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot(),
    MatTableModule,
    NgxSpinnerModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    HttpClientModule,
    MatInputModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      maxOpened: 1
    }),
  ],
  providers: [APP_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
