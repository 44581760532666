import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserService } from '../services/loggedin-user.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  public managerName = ''
  constructor(private router: Router,
    private loggedInUser: LoggedInUserService) { 
      this.managerName = this.loggedInUser.name;
    }

  ngOnInit() {
  }
  nextBtnClicked() {
    this.router.navigate(['']);
  }
}
