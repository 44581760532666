import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestBase, UtilService, LoggedInUserService } from 'src/app/services';
import { API_BASE_URL, API_END_POINTS, ERROR_OBJECTS } from 'src/app/services/constants';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public passwordType = 'password';
  public showButtonText: Boolean = false;
  apiEndPoint: any = API_END_POINTS;
  loginApiURL = `{API_BASE_URL}${this.apiEndPoint.login}`;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private requestBase: RequestBase,
    private util: UtilService,
    private loggedInUser: LoggedInUserService) {
    this.createForm();
    this.loggedInUser.logout();

  }

  ngOnInit() {

  }

  signin() {
    if (!this.loginForm.value['password']) {
      return;
    }

    let form = {};

      if (!this.loginForm.value['email']) {
        return;
      }
      form = {email: this.loginForm.value['email'], password: this.loginForm.value['password']};
      this.requestBase.login(form).subscribe(response => {
        this.router.navigate(['instructions']);
      });
  }


  public showPasswordClicked() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.showButtonText = true;
    } else {
      this.passwordType = 'password';
      this.showButtonText = false;
    }
  }
  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([this.util.required(), this.util.validateEmail])],
      password: ['', this.util.required()]
    });
  }


}
