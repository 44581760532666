<app-header></app-header>
<div class="rows-b">
  <div class="left">
    <div class="left-border">
    <div class="left-container">
    <!-- <p class="header">Welcome to Birla Century : Training improvement initiative</p>
    <div class="log-text">1. <p>We wish to gather data for employee training improvement programs.</p></div>
    <div class="log-text">2. <p>Please enter your email address and your password to login into your account.
      </p></div>
    <div class="log-text">3. <p>You will be provided with further instructions for navigating through the tool once you login.
      </p></div>
    <div class="log-text">4. <p>For any assistance, please contact the survey administrators.
      </p></div> -->
      <p class="firstLine">Welcome, <br> This is a Behavioural Assessment Tool</p>
      <p>What is Behaviour?</p>
      <p class="firstLine">Behaviour is an action or a set of actions (verbal & non-verbal) that are frequently displayed by an individual at the workplace.</p>
      <p class="firstLine">As a thumb rule, the actions that can be recorded by a camera would particularly classify as behaviours pertaining to an individual.
      </p>
      <P>About the Survey -</P>
      <p class="firstLine">This survey is being conducted in order to understand the behaviours that are constantly displayed by employees at workplace. This survey is from the perspective of employee development, training & building capabilities and is not linked to performance appraisal/compensation revision/promotion in any case whatsoever.
      </p>
      <p class="firstLine">Each of the following boxes contains a statement. They describe three possible behaviours that an employee can display in a workplace. You have to choose one of them based on your experience with the employee for whom you are taking the survey, at the workplace.</p>
      <p class="firstLine">Please read all the statements carefully and then click on the one that best describes the most <b class="bold">commonly observed behaviour</b> of the employee.</p>
      <p class="firstLine">The information being shared by you is confidential in nature.  </p>
      <p class="font-sez">Copyright ©: The tool is specially designed for Century Group</p>
    </div>
</div>
  </div>
  <div class="right">
<div class="login-wrapper">
  <form (ngSubmit)="signin()" [formGroup]="loginForm" novalidate>
    <h2 class="login-heading">Sign In</h2>
    <!-- <div class="form-group"> --> 
    <mat-form-field class="custom-form-field">
      <input matInput spellcheck="false" formControlName="email" placeholder="Email Address">
    </mat-form-field>
    <!-- </div> -->
    <!-- <div class="form-group custom-grop"> --> 
    <mat-form-field class="custom-form-field passsword-input">
      <input matInput spellcheck="false" [type]="passwordType" formControlName="password" placeholder="Password">
      <i class="fa fa-eye show-password" [ngClass]="showButtonText? 'fa-eye-slash': 'fa-eye'" aria-hidden="true" (click)="showPasswordClicked()"></i>  
      <!-- <i class="fa fa-eye-slash show-password" aria-hidden="true"></i> -->
    </mat-form-field>    
    <!-- </div>  -->
    <button type="submit"class="btn btn-primary custom-btn">Login</button>
    <br>
  </form>
</div>
</div>
</div>
