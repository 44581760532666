import { Injectable, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { API_BASE_URL } from './constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UtilService {
  nav: any = navigator;
  constructor(private http: HttpClient,
    private notificationService: ToastrService ) {

   }

  required() {
    return Validators.required;
  }

  maxLength(numberVar: number) {
    return Validators.maxLength(numberVar);
  }

  minLength(numberVar: number) {
    return Validators.minLength(numberVar);
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  public showMessage(message: string, isError: boolean) {
    if (isError) {
      this.notificationService.error(message);
    } else {
      this.notificationService.success(message);
    }
  }

  maxValue(max: Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (input > max) {
        return { 'maxValue': { max } };
      } else {
        return null;
      }
    };
  }

  minValue(min: Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (input < min) {
        return { 'minValue': { min } };
      } else {
        return null;
      }
    };
  }

  validateText(c: FormControl) {
    const str: string = c.value;
    return str.trim().length ? null : {
      inValidText: true
    };
  }

  validateEmail(c: FormControl) {
    /* tslint:disable-next-line */
    const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    return EMAIL_REGEXP.test(c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }
  validateEmailAndPhone(c: FormControl) {
    /* tslint:disable-next-line */
    const EMAIL_REGEXP = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    const Phone_REGEXP = /^\+?[0-9]?[0-9]?[0-9]?[0-9]?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

    return EMAIL_REGEXP.test(c.value) || Phone_REGEXP.test(c.value) ? null : {
      validateEmailAndPhone: {
        valid: false
      }
    };
  }

  validatePassword(formControl: FormControl) {
    // console.log(!!formControl.value && !!formControl.value.match(/[0-9]+/)
    // && formControl.value.length > 8;
    return !!formControl.value && !!formControl.value.match(/[0-9]+/)
    && formControl.value.length > 7 ? null : { validPassword: { valid: false}};
  }
}

  // share(jobObject) {
  //   console.log('utilobject', jobObject);
  //   if (this.nav.share) {
  //     this.nav.share({
  //       title: jobObject.title,
  //       text: jobObject.title,
  //       url: `${location.host}/employee/job/${jobObject.id}/detail`
  //     })
  //       .then(() => {
  //         console.log('Successful share');
  //         this.shareJob(jobObject.jobId.id);
  //       })
  //       .catch((error) => console.log('Error sharing', error));
  //   }
  // }
  // shareJob(jobId) {
  //   this.http.get(`${API_BASE_URL}/${jobId}/share`).subscribe(response => {
  //   }, error => {
  //   });
  // }


