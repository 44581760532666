import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { API_BASE_URL, API_END_POINTS } from 'src/app/services/constants';
import { LoggedInUserService } from 'src/app/services/loggedin-user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestBase {
  headers = new HttpHeaders();
  noPreFlightHeaders = new HttpHeaders();
  options = {
    headers: this.headers,
    withCredentials: true
  };
  optionsNoPre = {
    headers: this.noPreFlightHeaders,
    withCredentials: true
  };
  private apiEndPoints: any = API_END_POINTS;
  private apiBaseUrl = API_BASE_URL;
  constructor(
    public _http: HttpClient,
    private loggedInUser: LoggedInUserService
  ) {
    this.headers.append('Content-Type', 'application/json');
    // this.noPreFlightHeaders.append('Content-Type', 'text/plain');
  }
  get(url: string, options?: any) {
    return this._http.get(`${this.apiBaseUrl}${url}`);
  }
  put(url: string, body) {
    return this._http.put(url, body);
  }
  getWithParams(url: string, param) {
    return this._http.get(`${this.apiBaseUrl}${url}`, { params: param });
  }
  post(url: string, body?: any, headers?: any) {
    return this._http.post(`${this.apiBaseUrl}${url}`, body);
  }
  getFile(url: string, options: any) {
    return this._http.get(`${this.apiBaseUrl}${url}`, options);
  }
  getCSVFile(url: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv',
        Accept: 'text/csv'
      }),
      responseType: 'blob'
    };
    // Ensure you set the responseType to Blob.
    return this.getFile(url, options);
  }
  getCSVFileWithParams(url: string, params) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv',
        Accept: 'text/csv'
      }),
      responseType: 'blob',
      params: params
    };
    // Ensure you set the responseType to Blob.
    return this.getFile(url, options);
  }
  login(payload: any) {
    const loginString = payload.email + '/' + payload.password;
    return this.get(loginString).pipe(
      map((response: any) => {
        this.loggedInUser.accessToken = payload.password;
        this.loggedInUser.name = response.manager.name;
        this.loggedInUser.email = payload.email;
        this.loggedInUser.role = 'manager';
        return response;
      })
    );
  }
  changePassword(payload: any) {
    return this.post(this.apiEndPoints.changePassword, payload);
  }
  forgetPassword(payload: any) {
    return this.post(this.apiEndPoints.forgetPassword, payload);
  }
  resetPassword(payload: any) {
    return this.post(this.apiEndPoints.reset, payload);
  }
  
}
