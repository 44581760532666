<!--   Header Starts     -->
<nav class="navbar navbar-expand-md navbar-dark fixed-top navbar-wrapper">
  <div class="clearfix">
    <div class="header-name">
      <a class="navbar-brand float-left"><img src="assets/images/feedback_insights_logo.svg" alt="feedback"></a>
      <label class="person-name" *ngIf="showUser">Welcome {{managerName | titlecase}}</label>
      <div class="right-header">
        <a class="login float-right">
            <img src="assets/images/birla-century-logo.png" class="img-fluid  d-inline-block" width="150px" height="40px">
            <img src="assets/images/birla-estates-logo.png" class="img-fluid img-height d-inline-block" width="62px" height="62px">
            <!-- <img src="assets/images/birla-gold-premium-cement-logo.png" class="img-fluid login-img d-inline-block"> -->
            <img src="assets/images/century-enka-logo.png" class="img-fluid login-img d-inline-block">
            <img src="assets/images/century-pulp-paper-logo.png" class="img-fluid login-img century-heiht d-inline-block">
            <span (click)="logoutUser()" class="log-off" *ngIf="showUser">
            Logout
          </span>
        </a>
        
      </div>
    </div>
  </div>
</nav>
  <!--   Header Ends     -->