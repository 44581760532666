import { Injectable } from '@angular/core';


function createCookie(name, value, days) {
    let date, expires;

    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}

function readCookie(name) {
    const nameEQ = name + '=',
        ca = document.cookie.split(';');
    let i, c;

    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}
function setData(type, data) {
    // Convert data into JSON and encode to accommodate for special characters.
    data = encodeURIComponent(JSON.stringify(data));
    // Create cookie.
    if (type === 'session') {
        createCookie(getSessionName(), data, 365);
    } else {
        createCookie('localStorage', data, 365);
    }
}

function clearData(type) {
    if (type === 'session') {
        createCookie(getSessionName(), '', 365);
    } else {
        createCookie('localStorage', '', 365);
    }
}

function getData(type) {
    // Get cookie data.
    const data = type === 'session' ? readCookie(getSessionName()) : readCookie('localStorage');
    // If we have some data decode, parse and return it.
    return data ? JSON.parse(decodeURIComponent(data)) : {};
}

function getSessionName() {
    // If there is no name for this window, set one.
    // To ensure it's unquie use the current timestamp.
    if (!window.name) {
        window.name = new Date().getTime().toString();
    }
    return 'sessionStorage' + window.name;
}

@Injectable()
export class LocalStorageService {
    localStorage: any;
    sessionStorage: any;
    constructor() {
        try {
            // Test webstorage existence.
            if (!window.localStorage || !window.sessionStorage) {
                // throw 'exception';
            }
            // Test webstorage accessibility - Needed for Safari private browsing.
            localStorage.setItem('storage_test', '1');
            localStorage.removeItem('storage_test');
            this.localStorage = window.localStorage;
        } catch (e) {
            class CustomStorage {
                type;
                data;
                length = 0;
                constructor(type) {
                    this.type = type;
                    // Init data, if already present
                    this.data = getData(type);

                }
                clear() {
                    this.data = {};
                    this.length = 0;
                    clearData(this.type);
                }
                getItem(key) {
                    return this.data[key] === undefined ? null : this.data[key];
                }
                key(i) {
                    // not perfect, but works
                    let ctr = 0;
                    for (const k in this.data) {
                        if (ctr === i) {
                            return k;
                        } else {
                            ctr++;
                        }
                    }
                    return null;
                }
                removeItem(key) {
                    delete this.data[key];
                    this.length--;
                    setData(this.type, this.data);
                }
                setItem(key, value) {
                    this.data[key] = value + ''; // forces the value to a string
                    this.length++;
                    setData(this.type, this.data);
                }
            }

            // Replace window.localStorage and window.sessionStorage with out custom
            // implementation.

            const localStorage = new CustomStorage('local');
            const sessionStorage = new CustomStorage('session');
            Object.defineProperty(window, 'localStorage', { get: () => localStorage });
            this.localStorage = localStorage;
            this.sessionStorage = sessionStorage;

        }
    }
    setItem(key: string, value: any) {
        return this.localStorage.setItem(key, value);
    }
    getItem(key: string) {
        return this.localStorage.getItem(key);
    }
    clearItem(key: string) {
        return this.localStorage.removeItem(key);
    }
    clearAll() {
        return this.localStorage.clear();
    }
}
