<div class="main-container-wrapper">
  <app-header [showUser]=true></app-header>
  <div class="row table-body">
    <div class="col-sm-12">
      <div class="dashboard-table-wrapper">
        <div class="example-form">
          <!-- <div class="manager-name">Welcome {{managerName}}</div> -->
          <div #table class="example-container">

            <mat-table #table [dataSource]="dataSource" matSort>
              <!-- Title Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell class="survey-prj-header" *matHeaderCellDef mat-sort-header> Click name to assess employee </mat-header-cell>
                <mat-cell *matCellDef="let element" class="hyper-link" (click)="linkClickedForEmployee(element)">
                  {{element.name}} </mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="empId">
                <mat-header-cell class="survey-prj-header" *matHeaderCellDef mat-sort-header> Employee ID
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element.empId}}
                </mat-cell>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell class="survey-prj-header" *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ statusForSurvey(element) | titlecase }} </mat-cell>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="percent">
                <mat-header-cell class="survey-prj-header" *matHeaderCellDef mat-sort-header>Completion Percentage
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.percent || 0 }} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
              <mat-row [ngClass]="isCompleted(i) ? 'highlight' : ''"
                *matRowDef="let row; columns: displayedColumns;let i = index">

              </mat-row>
            </mat-table>

            <mat-paginator #paginator [length]="totalSurveys" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]"
              [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <!-- <button (click)="logoutUser()" class="btn-custom right-action">Logout</button> -->
  </div>
</div>