export const MOBILE =
  typeof window !== 'undefined' ? window.screen.availWidth < 800 : true;
  export const API_BASE_URL = `https://api.insightspulse.net/v1/login-hgs/get-manager-link/`;
// export const API_BASE_URL = `http://192.168.1.71:8000/v1/login-hgs/get-manager-link/`;
// export const API_BASE_URL = `https://pulse.insightsfeedback.net/v1`; // production


export const ERROR_OBJECTS: Object = {
  required: '{{field}} is required',
  notEquivalent: 'Fields are not equivalent',
  validateEmail: 'Please enter valid email',
  validateEmailAndPhone: 'Please enter valid email/phone number',
  inValidText: 'Please enter valid text',
  minValue: 'Please enter value greater than zero',
  validPassword: 'Password must contain minimum 8 characters and 1 number'
};
export const API_END_POINTS = {
    login: '/login',
    forgetPassword: '/forgot-password',
    reset: '/password-reset',
    createSurvey: '/admin/surveys/new',
    surveyList: '/admin/surveys',
    previewSurveyList: '/surveys',
    createQuestion: '/admin/questions/new',
    questionList: '/admin/questions/',
    changePassword: '/change_password',
    createUser: '/admin/users/create',
  };