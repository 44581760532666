import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class BrowserSupportGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private deviceDetector: DeviceDetectorService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     return this.browserSupported();
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.browserSupported();
  }
  canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    return this.browserSupported();
  }
  navigateToUnsupportedPage() {
    this.router.navigate(['/unsupportedBrowser']);
  }
  browserSupported(): boolean {
    const deviceInfo = this.deviceDetector.getDeviceInfo();
    const browser: string = deviceInfo.browser;
    // console.log('Device Info', deviceInfo);
    const os = deviceInfo.os;
    let browser_version = deviceInfo.browser_version ;
    if (browser_version) {
      browser_version = browser_version.split('.', 1)[0];
    }

    if (!(browser_version && browser_version.length > 0)) {
      this.navigateToUnsupportedPage();
      return false;
    }

    if (browser.toLowerCase().indexOf('ie') !== -1 && +browser_version >= 11) {
      // console.log('IE browser');
      return true;
    } else if (browser.toLowerCase().indexOf('ms-edge') !== -1 && +browser_version >= 16) {
      // console.log('IE Edge browser');
      return true;
    } else if (browser.toLowerCase().indexOf('chrome') !== -1 && +browser_version >= 59) {
      // console.log('Chrome Browser');
      return true;
    } else if (browser.toLowerCase().indexOf('firefox') !== -1 && +browser_version >= 53) {
      // console.log('Firefox Browser');
      return true;
    } else if (browser.toLowerCase().indexOf('opera') !== -1 && +browser_version >= 50) {
      // console.log('Opera Browser');
      return true;
    } else if (browser.toLowerCase().indexOf('safari') !== -1 && +browser_version >= 9) {
      // console.log('Safari Browser');
      return true;
    }

  this.navigateToUnsupportedPage();
  return false;
  }
}

